<template>
    <slide-out-menu
        :show="show"
        :has-separator="false"
        :has-padding="false"
        :loading="loading"
        header-class="p-6"
        size="sm"
        @close="onCloseSlideOut"
    >
        <template #slide-out-menu-header>
            <h5 class="text-xl font-medium text-gray-900">
                {{ $t('accountantProjects.newChat') }}
            </h5>
        </template>

        <div class="px-6">
            <form-control-group class="mb-6">
                <form-text-input
                    v-model="subject"
                    :error-message="subjectError"
                    :label="$t('accountantProjects.subject')"
                    :placeholder="$t('accountantProjects.subject')"
                />
                <form-date-picker
                    v-model="dueDate"
                    :error-message="dueDateError"
                    single
                    :label="$t('accountantProjects.dueDate')"
                />
                <form-rich-text-editor
                    v-model="message"
                    :error-message="messageError"
                />

                <div class="flex flex-col gap-3">
                    <form-toggle
                        v-model="state.enabledReminder"
                        :label="$t('accountantProjects.reminders')"
                        size="sm"
                    />
                    <form-control-group-row v-if="state.enabledReminder">
                        <form-date-picker
                            v-model="reminderStartDate"
                            :error-message="reminderStartDateError"
                            :label="$t('accountantProjects.startDate')"
                            :placeholder="
                                $t('accountantProjects.dateOfFirstReminder')
                            "
                            size="sm"
                            single
                        />
                        <form-single-select
                            v-model="reminder"
                            :error-message="messageReminderError"
                            :options="reminders"
                            position="top"
                            option-value="id"
                            option-label="name"
                            :label="$t('accountantProjects.sendReminders')"
                            size="sm"
                        />
                    </form-control-group-row>
                </div>
            </form-control-group>

            <div class="flex flex-col gap-2.5">
                <div class="flex items-center justify-between">
                    <h5 class="text-md font-medium text-gray-700">
                        {{ $t('accountantProjects.clientTasks') }}
                    </h5>
                    <base-button
                        icon="line-icons:general:plus"
                        class="!px-2"
                        outline
                        variant="default"
                        size="sm"
                        @click="onToggleAddSubTask"
                    />
                </div>

                <template v-if="fields.length > 0 || state.showAddSubTask">
                    <div
                        class="flex flex-col overflow-hidden rounded-md border border-gray-300"
                    >
                        <form-checkbox
                            v-for="(field, idx) in fields"
                            container-class="border-b border-gray-300 last:border-b-0"
                            label-class="px-2.5 py-3 hover:bg-gray-50"
                            :key="idx"
                            :model-value="
                                field.value.status === TASK_STATUS.DONE
                            "
                            @change="onToggleStatusSubtask(field)"
                        >
                            <template #text>
                                <span class="text-sm font-medium text-gray-500">
                                    {{ field.value.name }}
                                </span>
                            </template>
                        </form-checkbox>

                        <div
                            v-if="state.showAddSubTask"
                            class="flex gap-2 p-2.5"
                        >
                            <form-text-input
                                v-model="state.subTaskName"
                                :error-message="state.subTaskNameError"
                                :hide-message="true"
                                :placeholder="
                                    $t('accountantProjects.whatNeedToBeDone')
                                "
                                size="sm"
                                @keyup.enter="onSaveSubTask"
                            />
                            <base-button
                                variant="primary"
                                size="sm"
                                @click="onSaveSubTask"
                            >
                                {{ $t('general.save') }}
                            </base-button>
                            <base-button
                                variant="default"
                                @click="onToggleAddSubTask"
                            >
                                {{ $t('general.cancel') }}
                            </base-button>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <template #slide-out-menu-footer>
            <div
                class="mt-6 flex items-center justify-end gap-3 border-t border-gray-300 p-6"
            >
                <base-button variant="default" size="sm">
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button variant="primary" size="sm" @click="onCreateChat">
                    {{ $t('accountantProjects.createChat') }}
                </base-button>
            </div>
        </template>
    </slide-out-menu>
</template>
<script setup>
import { TASK_STATUS } from '@tenant/utils/constants'
import { useReminders } from '@tenant/composables/apis/use-reminders'

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    project: {
        type: Object,
        default: null,
    },
})
const emit = defineEmits(['slide-close', 'finish-create'])

const { reminders } = useReminders()
const state = reactive({
    enabledReminder: false,
    subTaskName: null,
    showAddSubTask: false,
})

watch(
    () => state.enabledReminder,
    (value) => {
        if (value) {
            return
        }

        setReminderStartDate(null)
        setReminder(null)
    }
)

watch(
    () => props.show,
    (value) => {
        if (value) {
            return
        }

        state.enabledReminder = false
        replace([])
        resetForm()
    }
)

watch(
    () => state.showAddSubTask,
    (value) => {
        if (value) {
            return
        }

        state.subTaskName = null
    }
)

const { execute, loading } = useApi(
    '/api/accountant/projects/client-tasks',
    'POST'
)

const { handleSubmit, resetForm, setErrors } = useForm()

const { value: subject, errorMessage: subjectError } = useField('subject')
const { value: dueDate, errorMessage: dueDateError } = useField('due_date')
const { value: message, errorMessage: messageError } = useField('message')
const {
    value: reminderStartDate,
    errorMessage: reminderStartDateError,
    setValue: setReminderStartDate,
} = useField('reminder_start_date')
const {
    value: reminder,
    errorMessage: messageReminderError,
    setValue: setReminder,
} = useField('reminder_id')
const { push, fields, replace } = useFieldArray('sub_tasks')

const onSaveSubTask = () => {
    push({
        status: TASK_STATUS.TODO,
        name: state.subTaskName,
    })

    state.subTaskName = null
    onToggleAddSubTask()
}

const onToggleAddSubTask = () => {
    state.showAddSubTask = !state.showAddSubTask
}

const onToggleStatusSubtask = (field) => {
    field.value.status =
        field.value.status === TASK_STATUS.DONE
            ? TASK_STATUS.TODO
            : TASK_STATUS.DONE
}

const onCreateChat = handleSubmit((values) => {
    let data = values

    if (props.project) {
        data = {
            ...data,
            project_id: props.project.id,
        }
    }
    execute({
        data,
    })
        .then((resp) => {
            emit('finish-create', resp)
        })
        .catch(({ errors }) => {
            setErrors(errors)
        })
})

const onCloseSlideOut = () => {
    emit('slide-close')
}
</script>
